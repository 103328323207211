import React from 'react'
import { Link } from 'gatsby'
import * as styles from './navigation.module.css'
import Search from "./search"

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
      <span className={styles.logo} />
      <span className={styles.navigationItem}>Home Gone Smart</span>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      {/* <li className={styles.navigationItem}>
        <Link to="/blog/" activeClassName="active">
          Articles
        </Link>
      </li> */}
      <li className={styles.navigationItem}>
        <Link to="/about" activeClassName="active">
          About
        </Link>
      </li>
      <li>
      <Search indices={searchIndices} />
        </li>
    </ul>
  </nav>
)

export default Navigation
